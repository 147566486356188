import { withStyles, Box, createStyles, Theme } from '@material-ui/core'

const styles = ({ breakpoints: { up } }: Theme) => {
  const lg = up('lg')
  return createStyles({
    root: {
      height: '1.0625rem',
      '& + &': {
        marginTop: '3rem',
        [lg]: {
          marginTop: '2.4375rem',
        },
      },
      [lg]: {
        height: '1.25rem',
        display: 'flex',
        alignItems: 'center',
      },
    },
  })
}

export default withStyles(styles)(Box)
