import React from 'react'
import {
  withStyles,
  WithStyles,
  createStyles,
  Typography,
  Theme,
} from '@material-ui/core'

import { ServicePlan } from '../../../../../../types/Service'

const styles = ({ breakpoints: { up } }: Theme) => {
  const sm = up('sm')
  const md = up('md')
  return createStyles({
    root: {
      marginBottom: '3rem',
      [sm]: {
        paddingLeft: '1.6875rem',
      },
      [md]: {
        paddingLeft: '0.4375rem',
      },
    },
    name: {
      fontWeight: 'bold',
      marginBottom: '1.375rem',
      fontSize: '1.8125rem',
      lineHeight: '2.125rem',
    },
    priceAndUnit: {
      display: 'flex',
      alignItems: 'flex-end',
      flexWrap: 'wrap',
      [sm]: {
        flexWrap: 'nowrap',
      },
    },
    price: {
      fontWeight: 'bold',
      marginRight: '0.6875rem',
      fontSize: '1.9rem',
      lineHeight: '2.875rem',
      [sm]: {
        fontSize: '2.25rem',
        lineHeight: 1,
      },
    },
    unit: {
      fontWeight: 500,
      fontSize: '1.0625rem',
      lineHeight: '1.25rem',
      color: '#848199',
    },
  })
}

interface Props extends WithStyles<typeof styles> {
  name: ServicePlan['type']['name']
  price: ServicePlan['price']
  unit: ServicePlan['unit']
}

const Info = ({ classes, name, price, unit }: Props) => {
  const formattedPrice: string = price.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
  })
  return (
    <div className={classes.root}>
      <div>
        <Typography color="primary" component="h3" className={classes.name}>
          {name}
        </Typography>
        <div className={classes.priceAndUnit}>
          <Typography className={classes.price}>{formattedPrice}</Typography>
          <Typography className={classes.unit}>/ {unit}</Typography>
        </div>
      </div>
    </div>
  )
}

export default withStyles(styles)(Info)
