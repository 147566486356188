import { withStyles, createStyles, Theme } from '@material-ui/core'
import PageContainer from '../components/PageContainer'

const styles = ({ breakpoints: { up } }: Theme) => {
  const xl = up('xl')
  const maxWidth = '72.0625rem'
  return createStyles({
    root: {
      [xl]: {
        maxWidth,
      },
    },
  })
}

export default withStyles(styles)(PageContainer)
