import React from 'react'
import { withStyles, WithStyles, createStyles, Theme } from '@material-ui/core'

import Title from './Title'

const styles = ({ breakpoints: { up } }: Theme) => {
  const md = up('md')
  return createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      listStyle: 'none',
      [md]: {
        marginBottom: '1.6875rem',
        flexBasis: '50%',
      },
      [up('xl')]: {
        marginBottom: '3.875rem',
      },
      '& + &': {
        marginTop: '1.9375rem',
        [md]: {
          marginTop: 0,
        },
      },
    },
  })
}

interface Props extends WithStyles<typeof styles> {
  titles: string[]
  icon: React.ReactNode
}

const Feature = ({ classes, titles, icon }: Props) => {
  const renderTitle = (title: string, index: number): JSX.Element => (
    <Title key={index}>{title}</Title>
  )

  const renderedTitles: JSX.Element[] = titles.map(renderTitle)

  return (
    <li className={classes.root}>
      {icon}
      <div>{renderedTitles}</div>
    </li>
  )
}

export default withStyles(styles)(Feature)
