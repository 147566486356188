import React from 'react'

import { Feature } from '../../../types/Service'
import Item from './Item'
import Features from '../../../components/Features'
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core'

const styles = ({ breakpoints: { up } }: Theme) => {
  const md = up('md')
  const lg = up('lg')
  return createStyles({
    root: {
      marginTop: '2.8125rem',
      [md]: {
        marginTop: '2.5rem',
      },
      [lg]: {
        marginTop: '3.5625rem',
      },
    },
  })
}

interface Props extends WithStyles<typeof styles> {
  features: Feature[]
}

function List({ features, classes }: Props) {
  const renderItem = (feature: Feature, index: number): JSX.Element => {
    return <Item {...feature} key={index} />
  }

  const renderedItems: JSX.Element[] = features.map(renderItem)

  return <Features classes={classes}>{renderedItems}</Features>
}

export default withStyles(styles)(List)
