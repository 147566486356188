import React from 'react'
import { withStyles, WithStyles } from '@material-ui/core'

import iconStyles from '../../../components/Features/Feature/styles'
import { Feature } from '../../../types/Service'
import IconContainer from '../../../components/Features/Feature/IconContainer'

interface Props extends WithStyles<typeof iconStyles> {
  icon: Feature['icon']
}

const Icon = ({ classes, icon: { localFile, alternativeText } }: Props) => {
  return (
    <IconContainer>
      <img src={localFile.url} alt={alternativeText} className={classes.root} />
    </IconContainer>
  )
}

export default withStyles(iconStyles)(Icon)
