import React from 'react'
import { withStyles, WithStyles, createStyles, Theme } from '@material-ui/core'

import Heads from './Heads'
import Plans from './Plans'
import Service, {
  ServicePlan,
  ServicePlanFeatureType,
} from '../../../../types/Service'
import { SlideIndex } from '../types'

interface BaseProps {
  slideIndex: SlideIndex
}

const defaultDisplay: string = 'flex'

const getDisplayOnMd = ({ slideIndex }: BaseProps): string => {
  return slideIndex === 2 ? 'none' : defaultDisplay
}

const getDisplayOnLg = ({ slideIndex }: BaseProps): string => {
  return slideIndex === 0 ? defaultDisplay : 'none'
}

const styles = ({ breakpoints: { up } }: Theme) => {
  const md = up('md')
  const lg = up('lg')
  return createStyles({
    root: {
      display: defaultDisplay,
      justifyContent: 'center',
      [md]: {
        display: getDisplayOnMd,
      },
      [lg]: {
        display: getDisplayOnLg,
      },
    },
  })
}

interface Props extends WithStyles<typeof styles>, BaseProps {
  servicePlanFeatureTypes: ServicePlanFeatureType[]
  plans: ServicePlan[]
  service: Service
}

const Slide = ({
  classes,
  servicePlanFeatureTypes,
  plans,
  service: {
    servicePlanFeatureTypes: servicePlanFeatureTypeIds,
    plans: planIds,
  },
  slideIndex,
}: Props) => {
  return (
    <div className={classes.root}>
      <Heads
        servicePlanFeatureTypeIds={servicePlanFeatureTypeIds}
        servicePlanFeatureTypes={servicePlanFeatureTypes}
      />
      <Plans
        planIds={planIds}
        plans={plans}
        featureTypeIds={servicePlanFeatureTypeIds}
        slideIndex={slideIndex}
      />
    </div>
  )
}

export default withStyles(styles)(Slide)
