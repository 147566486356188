import React from 'react'
import { withStyles, WithStyles, createStyles, Theme } from '@material-ui/core'

import Top from './Top'
import PageContainer from '../../components/PageContainer'
import Table from './Table'
import Service, { ServicePlanFeatureType } from '../../types/Service'

const styles = ({ breakpoints: { up } }: Theme) => {
  const md = up('md')
  const lg = up('lg')
  return createStyles({
    root: {
      marginBottom: '3.9375rem',
      [md]: {
        marginBottom: '10.125rem',
      },
      [lg]: {
        marginBottom: '12.75rem',
      },
    },
  })
}

interface Props extends WithStyles<typeof styles> {
  servicePlanFeatureTypes: ServicePlanFeatureType[]
  service: Service
}

const Prices = ({ classes, ...other }: Props) => {
  return (
    <section className={classes.root}>
      <PageContainer>
        <Top />
        <Table {...other} />
      </PageContainer>
    </section>
  )
}

export default withStyles(styles)(Prices)
