import React from 'react'
import { withStyles, WithStyles, createStyles, Theme } from '@material-ui/core'
import { graphql, useStaticQuery } from 'gatsby'

import { ServicePlan, ServicePlanFeature } from '../../../../../types/Service'
import { CollectionWithNodes, NodeWithId } from '../../../../../types/Nodes'
import Plan from './Plan'
import { getNodeById } from '../../../../../utils/find'
import { SlideIndex } from '../../types'
import { planDimensions } from '../styles'

const getWidth = (width: string, plans: number, margin: string): string => {
  const spacesInBetween: number = plans - 1
  return `calc(${width} * ${plans} + ${margin} * ${spacesInBetween})`
}

const styles = ({ breakpoints: { up } }: Theme) => {
  const md = up('md')
  const lg = up('lg')
  return createStyles({
    root: {
      display: 'flex',
      [md]: {
        maxWidth: getWidth(planDimensions.widthMd, 2, '2.8125rem'),
        justifyContent: 'space-between',
      },
      [lg]: {
        maxWidth: getWidth(planDimensions.widthLg, 3, '1.9375rem'),
      },
    },
  })
}

interface Props extends WithStyles<typeof styles> {
  plans: ServicePlan[]
  planIds: NodeWithId[]
  featureTypeIds: NodeWithId[]
  slideIndex: SlideIndex
}

interface Query {
  features: CollectionWithNodes<ServicePlanFeature>
}

const Plans = ({
  classes,
  slideIndex,
  planIds,
  plans,
  featureTypeIds,
}: Props) => {
  const {
    features: { nodes: features },
  }: Query = useStaticQuery(graphql`
    {
      features: allStrapiServicePlanFeatures {
        nodes {
          id: strapiId
          type {
            id
            name
          }
          value
        }
      }
    }
  `)

  const renderPlan = ({ id }: NodeWithId, index: number): JSX.Element => {
    const plan: ServicePlan = getNodeById<ServicePlan>(id, plans)
    return (
      <Plan
        {...plan}
        featureTypeIds={featureTypeIds}
        key={plan.id}
        allFeatures={features}
        slideIndex={slideIndex}
        planIndex={index}
      />
    )
  }

  const renderedPlans: JSX.Element[] = planIds.map(renderPlan)

  return <div className={classes.root}>{renderedPlans}</div>
}

export default withStyles(styles)(Plans)
