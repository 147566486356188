import { NodeWithId } from '../types/Nodes'
import { ServicePlanFeatureType } from '../types/Service'
import { findNodeById } from './find'

const addPlanType =
  (servicePlanFeatureTypes: ServicePlanFeatureType[]) =>
  (
    featureTypes: ServicePlanFeatureType[],
    featureType: NodeWithId
  ): ServicePlanFeatureType[] => {
    const featureTypeInfo = findNodeById<ServicePlanFeatureType>(
      featureType.id,
      servicePlanFeatureTypes
    )
    if (featureTypeInfo) {
      return [...featureTypes, featureTypeInfo]
    }
    return featureTypes
  }

export const getPlanTypesWithIds = (
  servicePlanFeatureTypes: ServicePlanFeatureType[],
  servicePlanFeatureTypeIds: NodeWithId[]
): ServicePlanFeatureType[] => {
  return servicePlanFeatureTypeIds.reduce(
    addPlanType(servicePlanFeatureTypes),
    []
  )
}
