import React from 'react'
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme,
  Typography,
} from '@material-ui/core'

const styles = ({ breakpoints: { up } }: Theme) => {
  const md = up('md')
  const lg = up('lg')
  return createStyles({
    root: {
      marginBottom: '2.3125rem',
      textAlign: 'center',
      [md]: {
        marginBottom: '4.4375rem',
      },
      [lg]: {
        marginBottom: '5.6875rem',
      },
    },
    title: {
      marginBottom: '1.0625rem',
    },
  })
}

interface Props extends WithStyles<typeof styles> {}

const Top = ({ classes }: Props) => {
  return (
    <div className={classes.root}>
      <Typography variant="h2" className={classes.title}>
        Simple, transparent pricing
      </Typography>
      <Typography color="textSecondary">
        No contracts. No suprise fees.
      </Typography>
    </div>
  )
}

export default withStyles(styles)(Top)
