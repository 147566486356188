import React from 'react'
import { withStyles, WithStyles, createStyles, Theme } from '@material-ui/core'

type BackgroundColor = string

type BackgroundColorStyle = {
  [selector: string]: {
    backgroundColor: BackgroundColor
  }
}

const addBackgroundStyles = (
  backgroundColorStyles: BackgroundColorStyle,
  backgroundColor: BackgroundColor,
  index: number,
  backgroundColors: BackgroundColor[]
): BackgroundColorStyle => {
  const position: number = index + 1
  return {
    ...backgroundColorStyles,
    [`& li:nth-of-type(${backgroundColors.length}n + ${position}) > div:first-of-type`]: {
      backgroundColor,
    },
  }
}

const getBackgroundStyles = (): BackgroundColorStyle => {
  const backgroundColors: BackgroundColor[] = [
    '#3C9A9D1A',
    'rgba(240, 95, 107, 0.1)',
    'rgba(103, 62, 102, 0.1)',
    'rgba(15, 105, 201, 0.1)',
  ]
  return backgroundColors.reduce(addBackgroundStyles, {})
}

const styles = ({ breakpoints: { up } }: Theme) => {
  const backgroundStyles: BackgroundColorStyle = getBackgroundStyles()
  return createStyles({
    root: {
      padding: 0,
      ...backgroundStyles,
      [up('md')]: {
        display: 'flex',
        flexWrap: 'wrap',
      },
    },
  })
}

interface Props extends WithStyles<typeof styles> {
  children: React.ReactNode
}

const Features = ({ classes, children }: Props) => {
  return <ul className={classes.root}>{children}</ul>
}

export default withStyles(styles)(Features)
