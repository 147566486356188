import React from 'react'
import { withStyles, WithStyles, createStyles, Theme } from '@material-ui/core'

import PageContainer from '../../components/PageContainer'
import HeroContent from '../../components/HeroContent'

const styles = ({ breakpoints: { up } }: Theme) => {
  const md = up('md')
  const lg = up('lg')
  const xl = up('xl')
  return createStyles({
    root: {
      marginBottom: '5.625rem',
      [md]: {
        marginTop: '0.3125rem',
        marginBottom: '5.625rem',
      },
      [xl]: {
        marginTop: '1.125rem',
        marginBottom: '10.4375rem',
      },
    },
    container: {
      [xl]: {
        maxWidth: '96.75rem',
        padding: 0,
      },
    },
    content: {
      borderRadius: '0.3125rem',
      background: '#235EEE0D',
      padding: '2.9375rem 0.8125rem',
      [md]: {
        padding: '4.4375rem 1.6875rem 2.6875rem',
      },
      [lg]: {
        padding: '8.4375rem 5rem',
      },
    },
  })
}

interface Props extends WithStyles<typeof styles> {}

const Hero = ({ classes }: Props) => {
  return (
    <section className={classes.root}>
      <PageContainer classes={{ root: classes.container }}>
        <div className={classes.content}>
          <HeroContent
            smallTitle="I will create"
            title={
              <>
                a website that generates <strong>Business</strong>
              </>
            }
            description="Stand out in today’s noisy marketplace and be seen!"
          />
        </div>
      </PageContainer>
    </section>
  )
}

export default withStyles(styles)(Hero)
