import React from 'react'
import { withStyles, WithStyles, createStyles } from '@material-ui/core'

import Layout from '../components/Layout'
import Service, {
  ServicePlan,
  ServicePlanFeature,
  ServicePlanFeatureType,
} from '../types/Service'
import { CollectionWithNodes } from '../types/Nodes'
import Introduction from '../components/Introduction'
import Features from './Features'
import RelatedProjects from './RelatedProjects'
import Testimonials from '../components/Testimonials'
import Social from '../components/Social'
import Contact from '../components/Contact'
import Hero from './Hero'
import Prices from './Prices'
import PageContainer from './PageContainer'
import Seo from '../components/Seo'

const styles = () => {
  return createStyles({})
}

interface AllStrapiServicePlans extends CollectionWithNodes<ServicePlan> {}

interface AllStrapiServicePlanFeatures
  extends CollectionWithNodes<ServicePlanFeature> {}

type Data = {
  strapiServices: Service
  allStrapiServicePlans: AllStrapiServicePlans
  allStrapiServicePlanFeatures: AllStrapiServicePlanFeatures
  allStrapiServicePlanFeatureTypes: CollectionWithNodes<ServicePlanFeatureType>
}

interface Props extends WithStyles<typeof styles> {
  data: Data
}

const ServicePage = ({
  data: { strapiServices: service, allStrapiServicePlanFeatureTypes },
}: Props) => {
  const { title, description, features, projects } = service

  return (
    <Layout>
      <Seo title={title} />
      <Hero />
      <Introduction introduction={description} Container={PageContainer} />
      <Features features={features} />
      <Prices
        service={service}
        servicePlanFeatureTypes={allStrapiServicePlanFeatureTypes.nodes}
      />
      <RelatedProjects projects={projects} />
      <Testimonials />
      <Social />
      <Contact />
    </Layout>
  )
}

export default withStyles(styles)(ServicePage)
