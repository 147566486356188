import { withStyles, createStyles, Theme } from '@material-ui/core'

import Slider from '../Slider'
import { flexBreakpoint } from '../Project/styles'

const styles = ({ breakpoints: { up } }: Theme) => {
  return createStyles({
    root: {
      [up(flexBreakpoint)]: {
        display: 'none',
      },
    },
  })
}

export default withStyles(styles)(Slider)
