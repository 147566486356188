import React from 'react'
import { withStyles, WithStyles } from '@material-ui/core'

import iconStyles from './styles'

interface Props extends WithStyles<typeof iconStyles> {
  Component: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
}

const Icon = ({ classes, Component }: Props) => {
  return <Component className={classes.root} />
}

export default withStyles(iconStyles)(Icon)
