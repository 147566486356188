import React from 'react'
import {
  withStyles,
  WithStyles,
  createStyles,
  Typography,
} from '@material-ui/core'

import { ServicePlanFeature } from '../../../../../../types/Service'
import Cell from '../../Cell'
import Close from '@material-ui/icons/Close'
import Checkmark from '@material-ui/icons/Check'

const styles = () => {
  return createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
    },
    text: {
      color: '#231D4F',
      fontSize: '1.0625rem',
      lineHeight: '1.25rem',
    },
  })
}

interface Props extends WithStyles<typeof styles>, ServicePlanFeature {}

const Feature = ({ classes, value }: Props) => {
  const renderValue = (): JSX.Element => {
    if (value === 'true') {
      return <Checkmark color="primary" />
    } else if (value === 'false') {
      return <Close color="error" />
    }
    return <Typography className={classes.text}>{value}</Typography>
  }

  return <Cell classes={{ root: classes.root }}>{renderValue()}</Cell>
}

export default withStyles(styles)(Feature)
