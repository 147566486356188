import React from 'react'
import { withStyles, WithStyles, createStyles, Theme } from '@material-ui/core'

import Head from './Head'
import Service, { ServicePlanFeatureType } from '../../../../../types/Service'
import { getPlanTypesWithIds } from '../../../../../utils/services'
import { buttonDimensions, planDimensions } from '../styles'

const styles = ({ breakpoints: { up } }: Theme) => {
  const paddingBottom = `calc(${buttonDimensions.paddingY} * 2 + ${buttonDimensions.lineHeight} + ${buttonDimensions.marginTop})`
  const md = up('md')
  const lg = up('lg')
  const xl = up('xl')
  return createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      marginRight: '2.625rem',
      justifyContent: 'flex-end',
      paddingBottom: paddingBottom,
      [md]: {
        paddingBottom: `calc(${paddingBottom} + ${planDimensions.paddingBottomMd})`,
      },
      [lg]: {
        marginRight: '5rem',
      },
      [xl]: {
        marginRight: '7.6875rem',
      },
    },
  })
}

interface Props extends WithStyles<typeof styles> {
  servicePlanFeatureTypeIds: Service['servicePlanFeatureTypes']
  servicePlanFeatureTypes: ServicePlanFeatureType[]
}

const Heads = ({
  classes,
  servicePlanFeatureTypeIds,
  servicePlanFeatureTypes,
}: Props) => {
  const planTypes: ServicePlanFeatureType[] = getPlanTypesWithIds(
    servicePlanFeatureTypes,
    servicePlanFeatureTypeIds
  )

  const renderItem = (planType: ServicePlanFeatureType): JSX.Element => {
    return <Head {...planType} key={planType.id} />
  }

  const renderedItems: JSX.Element[] = planTypes.map(renderItem)

  return <div className={classes.root}>{renderedItems}</div>
}

export default withStyles(styles)(Heads)
