import React from 'react'

import BaseTitle from '../../components/SectionToolbar/Title'

export default function Title() {
  return (
    <BaseTitle>
      Related
      <br />
      Projects Available
    </BaseTitle>
  )
}
