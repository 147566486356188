import React from 'react'
import { withStyles, WithStyles, createStyles, Theme } from '@material-ui/core'
import PageContainer from './PageContainer'

const styles = ({ breakpoints: { up } }: Theme) => {
  return createStyles({
    root: {
      marginBottom: '4.5rem',
      [up('md')]: {
        marginBottom: '5.625rem',
      },
      [up('lg')]: {
        marginBottom: '9rem',
      },
      [up('xl')]: {
        marginBottom: '14.8125rem',
      },
    },
  })
}

interface Props extends WithStyles<typeof styles> {
  children: React.ReactNode
}

const ProjectsSection = ({ classes, children }: Props) => {
  return (
    <section className={classes.root}>
      <PageContainer>{children}</PageContainer>
    </section>
  )
}

export default withStyles(styles)(ProjectsSection)
