import { NodeWithId } from '../types/Nodes'

type Identifier = NodeWithId['id'] | NodeWithId

const getIfNodeHasId =
  (identifier: Identifier) =>
  (node: NodeWithId): boolean => {
    // eslint-disable-next-line no-prototype-builtins
    const id: string = identifier.hasOwnProperty('id')
      ? (identifier as NodeWithId).id
      : (identifier as string)
    return id === node.id
  }

export const findNodeById = <Item extends NodeWithId>(
  id: Identifier,
  nodes: Item[]
): Item | undefined => {
  return nodes.find(getIfNodeHasId(id))
}

export const getNodeById = <Item extends NodeWithId>(
  id: Identifier,
  nodes: Item[]
): Item => {
  const node: Item | undefined = findNodeById(id, nodes)
  if (node) return node
  throw new Error(`Node with id ${id} not found`)
}
