import {
  createStyles,
  withStyles,
  WithStyles,
  Typography,
  Theme,
} from '@material-ui/core'
import React from 'react'

import CardSection from '../../components/CardSection'
import Service from '../../types/Service'
import List from './List'
import PageContainer from '../PageContainer'

const styles = ({ breakpoints: { up } }: Theme) => {
  const md = up('md')
  const lg = up('lg')
  return createStyles({
    root: {
      marginBottom: '4.4375rem',
      [md]: {
        marginBottom: '10.0625rem',
      },
      [lg]: {
        marginBottom: '8.4375rem',
      },
    },
  })
}

interface Props extends WithStyles<typeof styles> {
  features: Service['features']
}

function Features({ features: { description, features }, classes }: Props) {
  return (
    <CardSection
      classes={classes}
      Container={PageContainer}
      title={
        <Typography variant="h2">
          Offering these
          <br />
          Features
        </Typography>
      }
      content={description}
    >
      <List features={features} />
    </CardSection>
  )
}

export default withStyles(styles)(Features)
