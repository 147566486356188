import React from 'react'
import { withStyles, WithStyles, createStyles, Theme } from '@material-ui/core'

import { getSizeStyles } from '../../../utils/size'
import Icon from './Icon'

const styles = ({ breakpoints: { up } }: Theme) => {
  const sm = up('sm')
  const md = up('md')
  const lg = up('lg')
  const xl = up('xl')

  const lgContainerStyles = getSizeStyles('6.25rem')

  return createStyles({
    root: {
      marginRight: '1.875rem',
      borderRadius: '0.9375rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      ...getSizeStyles('3.25rem'),
      [sm]: {
        ...lgContainerStyles,
      },
      [md]: {
        ...getSizeStyles('3.5rem'),
        marginRight: '0.625rem',
      },
      [lg]: {
        ...getSizeStyles('4rem'),
        marginRight: '1rem',
      },
      [xl]: {
        ...lgContainerStyles,
        marginRight: '1.875rem',
      },
    },
  })
}

interface Props extends WithStyles<typeof styles> {
  Icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>> | null
  children?: React.ReactNode
}

const IconContainer = ({
  classes,
  Icon: Component = null,
  children = null,
}: Props) => {
  return (
    <div className={classes.root}>
      {Component && <Icon Component={Component} />}
      {children}
    </div>
  )
}

export default withStyles(styles)(IconContainer)
