import React from 'react'
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme,
  Typography,
} from '@material-ui/core'

import Title from './Title'
import MainTitle from './MainTitle'
import GetAQuoteButton from '../GetAQuoteButton'
import { heroMobileLgBk } from './styles'

const styles = (theme: Theme) => {
  const {
    breakpoints: { up },
    palette: {
      text: { secondary: textSecondaryColor },
    },
  } = theme

  const sm = up('sm')
  const mobileLg = up(heroMobileLgBk)
  const md = up('md')
  const lg = up('lg')
  const xl = up('xl')

  return createStyles({
    root: {
      [sm]: {
        flexShrink: 0,
        flexGrow: 1,
        flexBasis: '90.39%',
      },
      [mobileLg]: {
        flexBasis: '75%',
      },
      [md]: {
        paddingBottom: '1.3125rem',
        flexBasis: '31.1875rem',
      },
      [lg]: {
        paddingBottom: 0,
        flexBasis: '49.8125rem',
      },
    },
    smallTitle: {
      fontSize: '0.9375rem',
      lineHeight: '160%',
      [mobileLg]: {
        fontSize: '1.25rem',
      },
      [md]: {
        fontSize: '1.25rem',
        lineHeight: '1.75rem',
      },
      [lg]: {
        fontSize: '1.875rem',
        lineHeight: '2.1875rem',
      },
    },
    titles: {
      marginBottom: '0.75rem',
      [sm]: {
        maxWidth: '25.875rem',
      },
      [md]: {
        marginBottom: '0.8125rem',
        maxWidth: '31.1875rem',
      },
      [lg]: {
        maxWidth: '45rem',
      },
      [xl]: {
        maxWidth: '55rem',
        marginBottom: '0.6875rem',
      },
    },
    description: {
      color: textSecondaryColor,
      marginBottom: '1rem',
      [sm]: {
        /**
         * So the img doesn't cover the text
         */
        paddingRight: '1rem',
      },
      [mobileLg]: {
        fontSize: '0.9375rem',
        lineHeight: '1.575rem',
        marginBottom: '1.5625rem',
        maxWidth: '24.375rem',
      },
      [lg]: {
        fontSize: '1.25rem',
        lineHeight: '2.0625rem',
        maxWidth: '38.5rem',
        marginBottom: '2.625rem',
      },
    },
  })
}

interface Props extends WithStyles<typeof styles> {
  smallTitle: string
  title: React.ReactNode
  description: string
}

const Body = ({ classes, smallTitle, title, description }: Props) => {
  return (
    <div className={classes.root}>
      <Typography className={classes.titles} component="h1">
        <Title className={classes.smallTitle}>{smallTitle}</Title>
        <br />
        <MainTitle>{title}</MainTitle>
      </Typography>
      <Typography className={classes.description}>{description}</Typography>
      <GetAQuoteButton />
    </div>
  )
}

export default withStyles(styles)(Body)
