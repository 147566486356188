import React from 'react'
import { Hidden } from '@material-ui/core'
import { useStaticQuery, graphql } from 'gatsby'

import Slide from './Slide'
import { ServicePlan } from '../../../types/Service'
import { CollectionWithNodes } from '../../../types/Nodes'
import SliderForBreakpoint, { BaseProps } from './SliderForBreakpoint'

interface Query {
  plans: CollectionWithNodes<ServicePlan>
}

const Table = (props: BaseProps) => {
  const {
    plans: { nodes: plans },
  }: Query = useStaticQuery(graphql`
    {
      plans: allStrapiServicePlans {
        nodes {
          features {
            id
          }
          price
          type {
            name
          }
          id: strapiId
        }
      }
    }
  `)

  return (
    <div>
      <Hidden mdUp>
        <SliderForBreakpoint {...props} plans={plans} slidesAmount={3} />
      </Hidden>
      <Hidden smDown lgUp>
        <SliderForBreakpoint {...props} plans={plans} slidesAmount={2} />
      </Hidden>
      <Hidden mdDown>
        <Slide {...props} plans={plans} slideIndex={0} />
      </Hidden>
    </div>
  )
}

export default Table
