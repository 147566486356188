export const buttonDimensions = {
  paddingY: '0.8125rem',
  lineHeight: '1.125rem',
  marginTop: '3.125rem',
}

export const planDimensions = {
  widthMd: '13.25rem',
  widthLg: '17rem',
  paddingBottomMd: '3.125rem',
}
