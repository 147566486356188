import { createStyles, Theme } from '@material-ui/core'

import { getSizeStyles } from '../../../utils/size'

const iconStyles = ({ breakpoints: { up } }: Theme) => {
  const sm = up('sm')
  const md = up('md')
  const lg = up('lg')
  const xl = up('xl')
  const lgIconStyles = getSizeStyles('2.5rem')

  return createStyles({
    root: {
      ...getSizeStyles('1.9rem'),
      [sm]: {
        ...lgIconStyles,
      },
      [md]: {
        ...getSizeStyles('1.375rem'),
      },
      [lg]: {
        ...getSizeStyles('2rem'),
      },
      [xl]: {
        ...lgIconStyles,
      },
    },
  })
}

export default iconStyles
