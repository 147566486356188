import React from 'react'
import { withStyles, WithStyles, createStyles, Theme } from '@material-ui/core'

import { flexBreakpoint } from '../Project/styles'

const styles = ({ breakpoints: { up } }: Theme) => {
  return createStyles({
    root: {
      display: 'none',
      [up(flexBreakpoint)]: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        marginBottom: '3.6875rem',
      },
    },
  })
}

interface Props extends WithStyles<typeof styles> {
  children: React.ReactNode
}

const Grid = ({ classes, children }: Props) => {
  return <div className={classes.root}>{children}</div>
}

export default withStyles(styles)(Grid)
