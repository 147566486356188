import React from 'react'

import BaseFeature from '../../../components/Features/Feature'
import { Feature } from '../../../types/Service'
import Icon from './Icon'

interface Props extends Feature {}

export default function Item({ description, icon }: Props) {
  return <BaseFeature icon={<Icon icon={icon} />} titles={[description]} />
}
