import React from 'react'
import { withStyles, WithStyles, createStyles, Button } from '@material-ui/core'
import { buttonDimensions } from '../../styles'

const styles = () => {
  return createStyles({
    root: {
      borderRadius: '1.5rem',
      padding: `${buttonDimensions.paddingY} 2.5rem`,
      fontSize: '0.9375rem',
      lineHeight: buttonDimensions.lineHeight,
      marginTop: buttonDimensions.marginTop,
      fontWeight: 'bold',
    },
  })
}

interface Props extends WithStyles<typeof styles> {}

const ChoosePlan = ({ classes }: Props) => {
  return (
    <Button classes={classes} variant="contained" fullWidth color="primary">
      Choose plan
    </Button>
  )
}

export default withStyles(styles)(ChoosePlan)
