import { withStyles, createStyles, Theme } from '@material-ui/core'

import Title from './Title'
import { heroMobileLgBk } from './styles'

const styles = ({ palette, breakpoints: { up } }: Theme) => {
  return createStyles({
    root: {
      display: 'inline',
      fontSize: '1.5rem',
      lineHeight: '2rem',
      [up('sm')]: {
        fontSize: '1.875rem',
        lineHeight: '120%',
      },
      [up(heroMobileLgBk)]: {
        fontSize: '2.25rem',
      },
      [up('md')]: {
        fontSize: '2.9rem',
        lineHeight: '3.6875rem',
      },
      [up('lg')]: {
        fontSize: '4rem',
        lineHeight: '4.8rem',
      },
      [up('xl')]: {
        fontSize: '5rem',
        lineHeight: '5.875rem',
      },
      '& > strong': {
        color: palette.primary.main,
      },
    },
  })
}

export default withStyles(styles)(Title)
