import React from 'react'

import ProjectsSection from '../../components/ProjectsSection'
import SectionToolbar from '../../components/SectionToolbar'
import Title from './Title'
import Service from '../../types/Service'
import Projects from '../../components/Projects'

interface Props {
  projects: Service['projects']
}

const RelatedProjects = ({ projects }: Props) => {
  return (
    <ProjectsSection>
      <SectionToolbar title={<Title />} />
      <Projects projects={projects} />
    </ProjectsSection>
  )
}

export default RelatedProjects
