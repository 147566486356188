import React from 'react'
import {
  Typography,
  withStyles,
  WithStyles,
  createStyles,
  Theme,
} from '@material-ui/core'
import { ServicePlanFeatureType } from '../../../../../types/Service'
import Cell from '../Cell'

const styles = ({ breakpoints: { up } }: Theme) => {
  const lg = up('lg')
  return createStyles({
    text: {
      fontSize: '0.875rem',
      lineHeight: '1rem',
      fontWeight: 500,
      [lg]: {
        fontSize: '1.25rem',
        lineHeight: '1.4375rem',
      },
    },
  })
}

interface Props extends WithStyles<typeof styles>, ServicePlanFeatureType {}

const Head = ({ classes, name }: Props) => {
  return (
    <Cell>
      <Typography color="textSecondary" className={classes.text}>
        {name}
      </Typography>
    </Cell>
  )
}

export default withStyles(styles)(Head)
