import { withStyles, createStyles, Typography, Theme } from '@material-ui/core'

const styles = ({
  palette: {
    text: { primary: textPrimary },
  },
  breakpoints: { up },
}: Theme) => {
  return createStyles({
    root: {
      fontSize: '1.25rem',
      lineHeight: '2.1rem',
      fontWeight: 'bold',
      color: textPrimary,
      [up('md')]: {
        fontSize: '0.9375rem',
        lineHeight: '1.5625rem',
      },
      [up('xl')]: {
        fontSize: '1.25rem',
        lineHeight: '2.125rem',
      },
    },
  })
}

export default withStyles(styles)(Typography)
