import React from 'react'

import Slider from './Slider'
import ProjectItem from '../Project'
import { ProjectPreview } from '../../types/Project'
import Grid from './Grid'
import { RenderedProjects } from './types'

interface Props {
  projects: ProjectPreview[]
}

const List = ({ projects }: Props) => {
  const renderProject = (project: ProjectPreview): JSX.Element => (
    <ProjectItem {...project} key={project.slug} />
  )

  const renderedProjects: RenderedProjects = projects.map(renderProject)

  return (
    <>
      <Slider>{renderedProjects}</Slider>
      <Grid>{renderedProjects}</Grid>
    </>
  )
}

export default List
