import React from 'react'
import { withStyles, WithStyles, createStyles, Theme } from '@material-ui/core'

import Info from './Info'
import Feature from './Feature'
import ChoosePlan from './ChoosePlan'
import { NodeWithId } from '../../../../../../types/Nodes'
import {
  ServicePlan,
  ServicePlanFeature,
} from '../../../../../../types/Service'
import { SlideIndex } from '../../../types'
import { planDimensions } from '../../styles'
import { findNodeById } from '../../../../../../utils/find'

interface BaseProps {
  slideIndex: SlideIndex
  planIndex: number
}

const getDisplayOnXs = ({ slideIndex, planIndex }: BaseProps): string => {
  return slideIndex === planIndex ? 'block' : 'none'
}

const getDisplayOnMd = ({ slideIndex, planIndex }: BaseProps): string => {
  if (slideIndex === 0) {
    return planIndex !== 2 ? 'block' : 'none'
  }
  return planIndex === 2 ? 'block' : 'none'
}

const getBoxShadow = ({ planIndex }: BaseProps): string => {
  return planIndex === 1 ? '4px 8px 24px rgba(23, 18, 63, 0.120028)' : 'none'
}

const styles = ({ breakpoints: { up } }: Theme) => {
  const md = up('md')
  const lg = up('lg')
  return createStyles({
    root: {
      display: getDisplayOnXs,
      [md]: {
        display: getDisplayOnMd,
        width: planDimensions.widthMd,
        padding: `1.875rem 1.375rem ${planDimensions.paddingBottomMd}`,
      },
      [lg]: {
        display: 'block !important',
        padding: `${planDimensions.paddingBottomMd} 3.25rem`,
        boxShadow: getBoxShadow,
        width: planDimensions.widthLg,
        borderRadius: '0.625rem',
      },
    },
  })
}

interface Props extends WithStyles<typeof styles>, ServicePlan, BaseProps {
  featureTypeIds: NodeWithId[]
  allFeatures: ServicePlanFeature[]
}

/**
 * This shows a plan's feature type values.
 */
const Plan = ({
  classes,
  featureTypeIds,
  type: { name },
  price,
  unit,
  allFeatures,
  features,
}: Props) => {
  type RenderedCell = null | JSX.Element

  type PossibleFeature = ServicePlanFeature | undefined

  const getIfBelongsToPlan = (feature: ServicePlanFeature): boolean => {
    const match: NodeWithId | undefined = findNodeById(feature.id, features)
    return Boolean(match)
  }

  const getCheckIfFeatureHasType = (featureTypeNode: NodeWithId) => {
    return (feature: ServicePlanFeature): boolean => {
      const isCorrectType: boolean = feature.type.id === featureTypeNode.id
      const belongsToPlan: boolean = getIfBelongsToPlan(feature)
      return belongsToPlan && isCorrectType
    }
  }

  const findFeatureByType = (featureType: NodeWithId): PossibleFeature => {
    const getIfShouldInclude = getCheckIfFeatureHasType(featureType)
    return allFeatures.find(getIfShouldInclude)
  }

  const renderCell = (node: NodeWithId): RenderedCell => {
    const feature: PossibleFeature = findFeatureByType(node)
    if (!feature) return null
    return <Feature {...feature} key={feature.id} />
  }

  /**
   * We must iterate over the feature type nodes because we must display the
   * feature type values in the same order as the table's heads.
   */
  const renderedCells: RenderedCell[] = featureTypeIds.map(renderCell)

  return (
    <div className={classes.root}>
      <Info name={name} price={price} unit={unit} />
      <div>{renderedCells}</div>
      <ChoosePlan />
    </div>
  )
}

export default withStyles(styles)(Plan)
