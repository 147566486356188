import React from 'react'

import Service, {
  ServicePlan,
  ServicePlanFeatureType,
} from '../../../types/Service'
import Slide from './Slide'
import { SlideIndex } from './types'
import Slider from '../../../components/Slider'

export interface BaseProps {
  servicePlanFeatureTypes: ServicePlanFeatureType[]
  service: Service
}

interface Props extends BaseProps {
  plans: ServicePlan[]
  slidesAmount: number
}

const SliderForBreakpoint = ({ plans, slidesAmount, ...other }: Props) => {
  const addSlide = (slides: JSX.Element[], slideIndex: SlideIndex): void => {
    slides.push(<Slide {...other} plans={plans} slideIndex={slideIndex} />)
  }

  const renderSlides = (): JSX.Element[] => {
    const slides: JSX.Element[] = []
    for (let slideIndex = 0; slideIndex < slidesAmount; slideIndex++) {
      addSlide(slides, slideIndex)
    }
    return slides
  }

  const renderedSlides: JSX.Element[] = renderSlides()

  return <Slider>{renderedSlides}</Slider>
}

export default SliderForBreakpoint
