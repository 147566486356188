import React from 'react'
import {
  withStyles,
  Typography,
  createStyles,
  TypographyProps,
  WithStyles,
} from '@material-ui/core'

const styles = () => {
  return createStyles({
    root: {
      fontWeight: 'bold',
    },
  })
}

interface Props
  extends WithStyles<typeof styles>,
    Omit<TypographyProps, 'classes'> {}

const Title = (props: Props) => (
  <Typography color="textPrimary" variant="h1" component="span" {...props} />
)

export default withStyles(styles)(Title)
